<template>
  <div class="container">
    <table class="table company-info">
      <tbody>
        <tr>
          <td class="main title" colspan="4"><span>基本信息</span></td>
        </tr>
        <tr>
          <td class="title"><span>统一社会信用代码</span></td>
          <td><span class="text">9111010859963405XW</span></td>
          <td class="title"><span>企业名称</span></td>
          <td><span class="text">河南睿腾信息技术有限公司</span></td>
        </tr>
        <tr>
          <td class="title"><span>类型</span></td>
          <td><span class="text">有限责任公司(自然人投资或控股)</span></td>
          <td class="title"><span>法定代表人</span></td>
          <td><span class="text">程维</span></td>
        </tr>
        <tr>
          <td class="title"><span>注册资本</span></td>
          <td><span class="text">1000.000000万人民币</span></td>
          <td class="title"><span>成立日期</span></td>
          <td><span class="text">2012年07月10日</span></td>
        </tr>
        <tr>
          <td class="title"><span>营业期限自</span></td>
          <td><span class="text">2012年07月10日</span></td>
          <td class="title"><span>营业期限至</span></td>
          <td><span class="text">2032年07月09日</span></td>
        </tr>
        <tr>
          <td class="title"><span>登记机关</span></td>
          <td><span class="text">北京市工商行政管理局海淀分局</span></td>
          <td class="title"><span>住所</span></td>
          <td><span class="text">北京市海淀区东北旺西路8号院35号楼5层501室</span></td>
        </tr>
        <tr>
          <td class="title">范围</td>
          <td colspan="3">
            <span class="text">技术开发、技术咨询、技术服务、技术推广；基础软件服务；应用软件服务；设计、制作、代理、发布广告；软件开发；销售自行开发后的产品；企业管理咨询；计算机系统服务；组织文化艺术交流活动（不含营业性演出）；公共关系服务；企业策划、设计；会议服务；市场调查；货物进出口、技术进出口、代理进出口；从事互联网文化活动；互联网信息服务；经营电信业务。（企业依法自主选择经营项目，开展经营活动；从事互联网文化活动、互联网信息服务、经营电信业务以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事本市产业政策禁止和限制类项目的经营活动。）</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  min-width: 1200px;
  .table {
    margin: 50px auto;
    width: 800px;
    text-align: left;
    border-collapse: collapse;
    border-spacing: 0;
    td {
      border: 1px solid #e5e5e5;
    }
    .title {
      padding: 7px 12px;
      font-size: 14px;
      font-weight: 700;
      background: #f7f7f7;
      white-space: nowrap;
      text-align: right;
    }
    .text {
        padding: 5px 10px;
        display: block;
    }
    .main.title{
        text-align: center;
    }
  }
}
</style>